import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Globals } from './globals';
import { ApiService } from './api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {
  constructor(translate: TranslateService, private globals: Globals, private http: ApiService, private router: Router, private route : ActivatedRoute) {
    translate.addLangs(['en', 'fr']);
    translate.setDefaultLang('en');

    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');
  }


  ngOnInit() {

    if(localStorage.getItem('globalAccountId')) {      
      this.globals.globalAccountId = localStorage.getItem('globalAccountId');      
    }

    let currentYear = new Date().getFullYear();
    currentYear++;

    // for (let i = currentYear; i > 1989; i--) {
    //   this.globals.years.push(i);
    // }
    for (let i = currentYear; i > 1899; i--) {
      this.globals.years.push(i);
    }
    
    window.addEventListener('storage', (e) => {
        if (e.storageArea !== sessionStorage) { // if changed caused from another tab (change not caused by this tab)
            if (e.key == 'globalContacts') {
                const globalContacts = JSON.parse(localStorage.getItem(e.key));
                this.globals.allContacts = globalContacts['value'];
                this.globals.populateGlobalContactTypes();
            } else if (e.key == 'globalCompanies') {
                const globalCompanies = JSON.parse(localStorage.getItem(e.key));
                this.globals.allCompanies = globalCompanies['value'];
                this.globals.populateGlobalCompanyTypes();
            }  else if (e.key == 'globalTags') {
                const localStorageTags = JSON.parse(localStorage.getItem(e.key));
                this.globals.allTags = localStorageTags['value'];
                this.globals.populateGlobalTagTypes();
            } else if (e.key == 'globalLists') {
                const globalLists = JSON.parse(localStorage.getItem(e.key));
                this.globals.lists = globalLists['value'];
            } else if (e.key == 'globalExportTemplates') {
                const globalExportTemplates = JSON.parse(localStorage.getItem(e.key));
                this.globals.allExportTemplates = globalExportTemplates['value'];
                this.globals.setCustomExportTemplateVariables();
            } else if (e.key == 'globalClients') {
                const globalClients = JSON.parse(localStorage.getItem(e.key));
                this.globals.clients = globalClients['value'];
            }
            // Not needed as readOnlyRights for the user are not updated by the user.
            // else if (e.key == 'globalReadOnlyRights') {
            //     const globalReadOnlyRights = JSON.parse(localStorage.getItem(e.key));
            //     this.globals.readOnlyRights = globalReadOnlyRights['value'];
            // }
        }
    });

    //added by arba later for checking if delivery point and rdx is allowed or not before taking feedback from rdx
    // let currentUser : any = {};
    // let isRdxConnectionPointAllowed = false;

    // if(localStorage.getItem('token')){  //this check is important before calling any api which needs token
    //   this.http.get('/users/current/user', {})
    //   .subscribe((data : any) => {
    //     currentUser = data['currentUser'];
    //     this.http.get('/delivery-points/account', { })
    //     .subscribe((data : any) => {
    //       isRdxConnectionPointAllowed = data && data.some(point => point.name === 'RDx' || point.name === 'RDx - Sentric');
    //       console.log("currentUser, isRdxConnectionPointAllowed",currentUser, isRdxConnectionPointAllowed);//test
    //       if(currentUser && currentUser.canDeliver && isRdxConnectionPointAllowed){
    //           this.globals.rdxErrorFromXmlLoading = true;
    //           this.http.get('/tracks/xml-from-rdx', {})
    //           .subscribe((data : any) => {
    //             data.xmlData.map(xmlData => {
    //               // console.log("xmlData",xmlData);//test
    //               if(xmlData && xmlData.formattedMessageCreatedDateTime && xmlData.deliveryFeedbacks && xmlData.deliveryFeedbacks.length > 0){
    //                 xmlData.deliveryFeedbacks[0].formattedMessageCreatedDateTime = xmlData.formattedMessageCreatedDateTime;
    //                 this.globals.rdxErrorFromXml.push([...xmlData.deliveryFeedbacks])
    //               }
    //             });
    //             // console.log('this.globals.rdxErrorFromXml==',this.globals.rdxErrorFromXml);//test
    //             let isrcList = [];
    //             this.globals.rdxErrorFromXml.map(df => {
    //               df.map(subdf => {
    //                 if(subdf && subdf.ClaimedSoundRecording && subdf.ClaimedSoundRecording.length > 0 && subdf.ClaimedSoundRecording[0].SoundRecordingId && subdf.ClaimedSoundRecording[0].SoundRecordingId.length > 0 && subdf.ClaimedSoundRecording[0].SoundRecordingId[0].ISRC && subdf.ClaimedSoundRecording[0].SoundRecordingId[0].ISRC[0]){
    //                   isrcList.push(subdf.ClaimedSoundRecording[0].SoundRecordingId[0].ISRC[0])
    //                 }
    //               })
    //             })
    //             if(this.globals.rdxErrorFromXml.length > 0 && this.globals.rdxErrorFromXml[0].length > 0 && this.globals.rdxErrorFromXml[0][0]){
    //               this.globals.rdxErrorFromXml[0][0].isrcList=isrcList;
    //             }
    //             this.globals.rdxErrorFromXmlLoading = false;
    //           },
    //           (err) => {    
    //             this.globals.rdxErrorFromXmlLoading = false;
    //             this.globals.rdxErrorFromXmlError = true;
    //           }
    //           );
    //       }
    //     });
    //   });
    // }
  //added by arba later ended

    this.http.get('/users/current/type', {})
      .subscribe((data: any) => {
        this.globals.userType = data['type'];
        this.globals.userSubType = data['subType'];
        this.http.get('/users/current/name', {})
          .subscribe((data: any) => {
            this.globals.userName = data;
            this.http.get('/users/current/access', {})
              .subscribe((data: any) => {
                // console.log(this.router.url);
                this.globals.userAccess = data;
                if (this.globals.userAccess.contracts == true && this.globals.userAccess.assets == true)
                  this.globals.redirectPage = 'dashboard';
                else if (this.globals.userAccess.accounts == true)
                  this.globals.redirectPage = 'accounts';
                else if (this.globals.userAccess.contracts == true)
                  this.globals.redirectPage = 'contracts';
                else if (this.globals.userAccess.releases == true)
                  this.globals.redirectPage = 'releases';
                else if (this.globals.userAccess.assets == true)
                  this.globals.redirectPage = 'assets';
                else if (this.globals.userAccess.works == true)
                  this.globals.redirectPage = 'works';
                else if (this.globals.userAccess.audiobank == true)
                    this.globals.redirectPage = 'content-bank';
                else if (this.globals.userAccess.importsSection == true)
                    this.globals.redirectPage = 'imports';
                else if (this.globals.userAccess.exportsSection == true)
                    this.globals.redirectPage = 'exports';
                else if (this.globals.userAccess.stems == true)
                    this.globals.redirectPage = 'stems';
                else if (this.globals.userAccess.playlists == true)
                    this.globals.redirectPage = 'playlists';
                else if (this.globals.userAccess.contacts == true)
                    this.globals.redirectPage = 'individuals';
                else if (this.globals.userAccess.companies == true)
                    this.globals.redirectPage = 'companies';
                else if (this.globals.userAccess.pages == true)
                    this.globals.redirectPage = 'pages';
                else if (this.globals.userAccess.publishers == true)
                  this.globals.redirectPage = 'publishers';
                else if (this.globals.userAccess.composers == true)
                  this.globals.redirectPage = 'composers';
                else if (this.globals.userAccess.datadoktor == true)
                    this.globals.redirectPage = 'datadoktor';
                else if (this.globals.userAccess.statements == true)
                  this.globals.redirectPage = 'statements';
                else if (this.globals.userAccess.templates == true)
                    this.globals.redirectPage = 'templates';
                else if (this.globals.userAccess.connections == true)
                    this.globals.redirectPage = 'connections';
                else if (this.globals.userAccess.users == true)
                  this.globals.redirectPage = 'users';
                else if (this.globals.userAccess.clients == true)
                  this.globals.redirectPage = 'clients';
                else if (this.globals.userAccess.lists == true)
                    this.globals.redirectPage = 'lists';
                else if (this.globals.userAccess.trackAndTrace == true)
                    this.globals.redirectPage = 'rightstrack';

                this.http.get('/users/current/unpaid', {})
                  .subscribe((data: any) => {
                    this.globals.userUnpaid = data.unpaid;
                    // if (localStorage.getItem('token') && window.location.href.indexOf('/page/') == -1)
                      // this.router.navigate(['/' + this.globals.redirectPage]);
                  });

                // If the current location isn't a public page then load global variables.
                if (!window.location.href.includes('/page') || window.location.href.includes('/edit')) {
                    
                  //to do check userType and if Internal check if globalAccountId is selected
                  if(this.globals.userType != 'Internal' || (this.globals.userType == 'Internal' && this.globals.globalAccountId)){
                      this.getLocalStorageData();
                  }
                  const now = Date.now();
                  let globalItem;
                  if (this.globals.userType == 'Internal') {
                      globalItem = JSON.parse(localStorage.getItem('globalAccountList'));
                      if (globalItem != null && globalItem['expiry'] != null && now < globalItem['expiry']) {
                          this.globals.accountList = globalItem['value'];
                          console.log('loaded existing globalAccountList from local storage');
                      } else {
                          this.populateGlobalAccountList();
                      }
                  }
                  globalItem = JSON.parse(localStorage.getItem('globalClients'));
                  if (globalItem != null && globalItem['expiry'] != null && now < globalItem['expiry']) {
                      this.globals.clients = globalItem['value'];
                      console.log('loaded existing globalClients from local storage');
                  } else {
                      this.populateGlobalClients();
                  }
                  
                }
              });
          });

      });
  }

  // Initialized the globally stored arrays of tags.
  populateGlobalTags() {
      this.http.get('/tags/projection-fields', { limit: 10000, projection: this.globals.allTagsProjectionFields })
          .subscribe((data) => {
              this.globals.allTags = data['tags'];
              this.globals.populateGlobalTagTypes();
              this.globals.setLocalStorageItem('globalTags', this.globals.allTags, true);
          });
  }

  // Initialize the globally stored array of lists.
  populateGlobalLists() {
    this.http.get('/lists', { limit: 1000 })
      .subscribe((data) => {
        this.globals.lists = data['lists'];
        this.globals.setLocalStorageItem('globalLists', this.globals.lists, true);
      });
  }

  populateGlobalExportTemplates() {
    this.http.get('/export_templates', { limit: 10000 })
      .subscribe((data) => {
        this.globals.allExportTemplates = data['export_templates'];
        this.globals.setCustomExportTemplateVariables();
        this.globals.setLocalStorageItem('globalExportTemplates', this.globals.allExportTemplates, true);
      });
  }

  populateGlobalClients() {
    this.http.get('/clients', { limit: 10000 })
      .subscribe((data) => {
        this.globals.clients = data['clients'];
        this.globals.setLocalStorageItem('globalClients', this.globals.clients, true);
      });
  }

  populateReadOnlyRights() {
    this.http.get('/users/current/rights', {})
      .subscribe((data: any) => {
          if (data && data['readOnlyRights']) {
              this.globals.readOnlyRights = data['readOnlyRights'];
          } else if (data && data['oldRightsVariable'] && data['oldRightsVariable'] == 'Read-Only') {
              this.globals.readOnlyRights = this.globals.defaultReadOnlyRights;
              for (const setProperty in this.globals.readOnlyRights) {
                  this.globals.readOnlyRights[setProperty] = true;
              }
          } else {
              this.globals.readOnlyRights = this.globals.defaultReadOnlyRights;
          }
          this.globals.setLocalStorageItem('globalReadOnlyRights', this.globals.readOnlyRights, true);
      });
  }
  
  populateGlobalContacts() {
      this.http.get('/contacts/projection-fields', { limit: 10000, projection: this.globals.allContactsProjectionFields })
          .subscribe((data) => {
              this.globals.allContacts = data['contacts'];
              this.globals.populateGlobalContactTypes();
              this.globals.setLocalStorageItem('globalContacts', this.globals.allContacts, true);
          });
  }
  
  populateGlobalCompanies() {
      this.http.get('/companies/projection-fields', { limit: 10000, projection: this.globals.allCompaniesProjectionFields })
          .subscribe((data) => {
              this.globals.allCompanies = data['companies'];
              this.globals.populateGlobalCompanyTypes();
              this.globals.setLocalStorageItem('globalCompanies', this.globals.allCompanies, true);
          });
  }

  populateGlobalAccountList() {
      this.http.get('/accounts/selector-list', { limit: 10000 })
          .subscribe((data) => {
              this.globals.accountList = data['accounts'];
              this.globals.accountList.sort((a, b) => a.name - b.name);

              this.globals.accountList.push({name: 'Unassigned', _id: 'unassigned'}); // added later by arba-p for filtering data with no accounts
              
              this.globals.setLocalStorageItem('globalAccountList', this.globals.accountList, true);
          });
  }
  
  // check the local storage for global contact/company data and updates if non-existent or expired.
  getLocalStorageData() {
      // console.log('getLocalStorageData start');
      // console.log(localStorage);
      
      const now = Date.now();
      
      let globalItem = JSON.parse(localStorage.getItem('globalContacts'));
      if (globalItem != null && globalItem['expiry'] != null && now < globalItem['expiry']
          && globalItem['value'] && globalItem['value'].length > 5) {
          this.globals.allContacts = globalItem['value'];
          this.globals.populateGlobalContactTypes();
          console.log('loaded existing globalContacts from localStorage');
      } else {
          this.populateGlobalContacts();
      }
      
      globalItem = JSON.parse(localStorage.getItem('globalCompanies'));
      if (globalItem != null && globalItem['expiry'] != null && now < globalItem['expiry']
          && globalItem['value'] && globalItem['value'].length > 5) {
          this.globals.allCompanies = globalItem['value'];
          this.globals.populateGlobalCompanyTypes();
          console.log('loaded existing globalCompanies from local storage');
      } else {
          this.populateGlobalCompanies();
      }

      globalItem = JSON.parse(localStorage.getItem('globalTags'));
      if (globalItem != null && globalItem['expiry'] != null && now < globalItem['expiry']) {
          this.globals.allTags = globalItem['value'];
          this.globals.populateGlobalTagTypes();
          
          console.log('loaded existing globalTags from local storage');
      } else {
          this.populateGlobalTags();
      }

      globalItem = JSON.parse(localStorage.getItem('globalLists'));
      if (globalItem != null && globalItem['expiry'] != null && now < globalItem['expiry']) {
          this.globals.lists = globalItem['value'];
          console.log('loaded existing globalLists from local storage');
      } else {
          this.populateGlobalLists();
      }

      globalItem = JSON.parse(localStorage.getItem('globalExportTemplates'));
      if (globalItem != null && globalItem['expiry'] != null && now < globalItem['expiry']) {
          this.globals.allExportTemplates = globalItem['value'];
          this.globals.setCustomExportTemplateVariables();
          console.log('loaded existing globalExportTemplates from local storage');
      } else {
          this.populateGlobalExportTemplates();
      }

      globalItem = JSON.parse(localStorage.getItem('globalClients'));
      if (globalItem != null && globalItem['expiry'] != null && now < globalItem['expiry']) {
          this.globals.clients = globalItem['value'];
          console.log('loaded existing globalClients from local storage');
      } else {
          this.populateGlobalClients();
      }

      globalItem = JSON.parse(localStorage.getItem('globalReadOnlyRights'));
      if (globalItem != null && globalItem['expiry'] != null && now < globalItem['expiry']) {
          this.globals.readOnlyRights = globalItem['value'];
          console.log('loaded existing globalReadOnlyRights from local storage');
      } else {
          this.populateReadOnlyRights();
      }
  }
}
