import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Globals } from './globals';
import { timeout } from 'rxjs/operators'; //added later

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient, private globals: Globals) { }

  get(url: string, params: {}) {
    if (this.globals.globalAccountId) {
      params['accountId'] = this.globals.globalAccountId;
    }

    if (this.globals.demoMode == true) {
      params['demo'] = true;
    }

    return this.http.get(this.globals.api_url.concat(url), { params: params });
  }

  //This method is to specify manual timeout for the request being processed
  getWithTimeOut(url: string, params: {}, timeoutValue) {
    if (this.globals.globalAccountId) {
      params['accountId'] = this.globals.globalAccountId;
    }

    if (this.globals.demoMode == true) {
      params['demo'] = true;
    }

    return this.http.get(this.globals.api_url.concat(url), { params: params }).pipe(
      timeout(timeoutValue)
    );
  }

  getExternal(url: string, params: {}) {
    return this.http.get(url, { params: params });
  }

  post(url: string, body: {}, params: {}) {
    //Added by arba-N
    if (this.globals.globalAccountId && url === '/releases/getReleases') {
      body['accountId'] = this.globals.globalAccountId;
    }
    return this.http.post(this.globals.api_url.concat(url), body, { params: params });
  }

  postToS3(url: string, body: {}) {
    return this.http.post(url, body, { responseType: 'text' });
  }

  postToS3WithProgress(url: string, body: {}) {
    const req = new HttpRequest('POST', url, body, { reportProgress: true, responseType: 'text' });

    return this.http.request(req);
  }

  postWithProgress(url: string, body: {}) {
    const req = new HttpRequest('POST', this.globals.api_url.concat(url), body, { reportProgress: true });
    return this.http.request(req);
  }

  postWithoutProgress(url: string, body: {}) {
    const req = new HttpRequest('POST', this.globals.api_url.concat(url), body, { reportProgress: false });
    return this.http.request(req);
  }

  put(url: string, body: {}, params: {}) {
    if (this.globals.globalAccountId) {
      params['accountId'] = this.globals.globalAccountId;
    }
    return this.http.put(this.globals.api_url.concat(url), body, { params: params });
  }

  export(url: string, body) {
    if (this.globals.globalAccountId) {
      body['accountId'] = this.globals.globalAccountId;
    }
    
    return this.http.post(this.globals.api_url.concat(url), body, { responseType: 'blob' });
  }

  exportWithProgress(url: string, body) {
    // const req = new HttpRequest('POST', this.globals.api_url.concat(url), body, {reportProgress: true, responseType: 'blob', headers: new HttpHeaders({ 'Content-Type': 'application/zip' })});

    // return this.http.request(req);
    return this.http.post(this.globals.api_url.concat(url), body, { responseType: 'blob', reportProgress: true });
  }

  delete(url: string, params: {}) {
    return this.http.delete(this.globals.api_url.concat(url), { params: params });
  }

  downloadS3(url: string) {
    const req = new HttpRequest('GET', url, { responseType: 'blob', reportProgress: true });
    return this.http.request(req);
  }

  //added later by arba-p for calling beta-api for large import and export
  // betaGet(url: string, params: {}) {
  //   if (this.globals.globalAccountId) {
  //     params['accountId'] = this.globals.globalAccountId;
  //   }

  //   if (this.globals.demoMode == true) {
  //     params['demo'] = true;
  //   }

  //   return this.http.get(this.globals.betaUrl.concat(url), { params: params });
  // }

  // betaExport(url: string, body) {
  //   if (this.globals.globalAccountId) {
  //     body['accountId'] = this.globals.globalAccountId;
  //   }
    
  //   return this.http.post(this.globals.betaUrl.concat(url), body, { responseType: 'blob' });
  // }

  // betaPostWithProgress(url: string, body: {}) {
  //   const req = new HttpRequest('POST', this.globals.betaUrl.concat(url), body, { reportProgress: true });
  //   return this.http.request(req);
  // }

}
