import { Component, OnInit } from '@angular/core';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit{
  currentYear;
  constructor(private globals : Globals){}
  ngOnInit(){
    this.currentYear = new Date().getFullYear();
  }
}
