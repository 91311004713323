import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Globals } from '../../globals';

@Component({
  selector: 'app-territories',
  templateUrl: './territories.component.html',
  styleUrls: ['./territories.component.css']
})
export class TerritoriesComponent implements OnInit {
  @Input() selectedTerritories;
  @Input() filterField;
  @Input() cardHeaderTitle;
  @Input() disabled = false;
  @Input() hideAll = true;
  @Output() selectedTerritoriesChange = new EventEmitter<boolean>();

  // hideAll = true;
  territories;
  
  continents = ['Africa', 'Americas', 'Asia', 'Europe', 'Oceania'];

  // checkedContinents: {} = {
  //   Africa: false,
  //   Americas: false,
  //   Asia: false,
  //   Europe: false,
  //   Oceania: false,
  //   World: false
  // };

  // continentsWithOneChecked: {} = {
  //   Africa: false,
  //   Americas: false,
  //   Asia: false,
  //   Europe: false,
  //   Oceania: false
  // };

  constructor(private globals: Globals) { }

  ngOnInit() {
    this.territories = this.globals.territoriesWithContinents;
  }

  
  ngAfterViewInit() {
    // this.isWorldChecked();
    // this.isContinentChecked('Africa');
    // this.isContinentChecked('Americas');
    // this.isContinentChecked('Asia');
    // this.isContinentChecked('Europe');
    // this.isContinentChecked('Oceania');
  }

  isCountryChecked(code) {
    if (!this.selectedTerritories || !Array.isArray(this.selectedTerritories)) {
      return false;
    } else if (this.selectedTerritories.indexOf(code) > -1) {
      return true;
    } else {
      return false;
    }
  }

  // addCountry(code, continent) {
  //   if (this.selectedTerritories.indexOf(code) > -1) {
  //     this.selectedTerritories = this.selectedTerritories.filter(item => item !== code);
  //   } else {
  //     if (this.selectedTerritories.indexOf(code) === -1) {
  //       this.selectedTerritories.push(code);
  //     }
  //   }
  //   this.selectedTerritoriesChange.emit(this.selectedTerritories);
  //   // this.isContinentChecked(continent);
  // }

  addCountry(code, continent) {
    if (this.selectedTerritories.indexOf(code) > -1) {
      this.selectedTerritories = this.selectedTerritories.filter(item => item !== code);
    } else {
      if (this.selectedTerritories.indexOf(code) === -1) {
        this.selectedTerritories.push(code);
      }
    }
    this.selectedTerritoriesChange.emit(this.selectedTerritories);
    // this.isContinentChecked(continent);
  }

  // checkWorld() {
  //   // this.checkedContinents['World'] = !this.checkedContinents['World'];
  //
  //   if (this.checkedContinents['World']) {
  //     this.continents.map(continent => {
  //       for (let i = 0; i < this.territories[continent].length; i++) {
  //         if (this.selectedTerritories.indexOf(this.territories[continent][i]['code']) === -1) {
  //           this.selectedTerritories.push(this.territories[continent][i]['code']);
  //         }
  //       }
  //     });
  //
  //     Object.keys(this.checkedContinents).forEach(v => this.checkedContinents[v] = true);
  //     // Object.keys(this.continentsWithOneChecked).forEach(v => this.continentsWithOneChecked[v] = true);
  //   } else {
  //     this.selectedTerritories = [];
  //
  //     Object.keys(this.checkedContinents).forEach(v => this.checkedContinents[v] = false);
  //     // Object.keys(this.continentsWithOneChecked).forEach(v => this.continentsWithOneChecked[v] = false);
  //   }
  //
  //   this.selectedTerritoriesChange.emit(this.selectedTerritories);
  // }

  checkWorld2() {
    if (!this.isWorldChecked2()) {
      this.selectedTerritories = [];
      this.continents.map(continent => {
        for (let i = 0; i < this.territories[continent].length; i++) {
          this.selectedTerritories.push(this.territories[continent][i]['code']);
        }
      });
    } else {
      this.selectedTerritories = [];
    }
    
    this.selectedTerritoriesChange.emit(this.selectedTerritories);
  }

  // checkContinent(continent) {
  //   this.checkedContinents[continent] = !this.checkedContinents[continent];
  //
  //   if (this.checkedContinents[continent]) {
  //     for (var i = 0; i < this.territories[continent].length; i++) {
  //       if (this.selectedTerritories.indexOf(this.territories[continent][i]['code']) === -1) {
  //         this.selectedTerritories.push(this.territories[continent][i]['code']);
  //       }
  //     }
  //     // this.continentsWithOneChecked[continent] = true;
  //   } else {
  //     for (var i = 0; i < this.territories[continent].length; i++) {
  //       this.selectedTerritories = this.selectedTerritories.filter(item => item !== this.territories[continent][i]['code']);
  //     }
  //     // this.continentsWithOneChecked[continent] = false;
  //   }
  //  
  //   this.selectedTerritoriesChange.emit(this.selectedTerritories);
  // }

  checkContinent2(continent) {
    if (!this.isContinentChecked2(continent)) {
      for (let i = 0; i < this.territories[continent].length; i++) {
        if (this.selectedTerritories.indexOf(this.territories[continent][i]['code']) === -1) {
          this.selectedTerritories.push(this.territories[continent][i]['code']);
        }
      }
    } else {
      for (let i = 0; i < this.territories[continent].length; i++) {
        this.selectedTerritories = this.selectedTerritories.filter(item => item !== this.territories[continent][i]['code']);
      }
    }

    this.selectedTerritoriesChange.emit(this.selectedTerritories);
  }

  // isContinentChecked(continent) {
  //   this.checkedContinents[continent] = true;
  //   // this.continentsWithOneChecked[continent] = false;
  //   for (var i = 0; i < this.territories[continent].length; i++) {
  //     if (this.selectedTerritories.indexOf(this.territories[continent][i]['code']) === -1) {
  //       this.checkedContinents[continent] = false;
  //     } else {
  //       // this.continentsWithOneChecked[continent] = true;
  //     }
  //   }
  // }

  isContinentChecked2(continent) {
    if (!this.selectedTerritories || !Array.isArray(this.selectedTerritories)) { return false; }

    const isContinentChecked = this.territories[continent].every(country => this.selectedTerritories.includes(country.code));
    return isContinentChecked;
  }

  // isWorldChecked() {
  //   if (this.selectedTerritories.length >= 249) {
  //     Object.keys(this.checkedContinents).forEach(v => this.checkedContinents[v] = true);
  //     // Object.keys(this.continentsWithOneChecked).forEach(v => this.continentsWithOneChecked[v] = true);
  //     return true;
  //   } else {
  //     this.checkedContinents['World'] = false;
  //     return false;
  //   }
  // }

  isWorldChecked2() {
    if (!this.selectedTerritories || !Array.isArray(this.selectedTerritories)) {
      return false;
    } else if (this.selectedTerritories.length >= 249) {
      return true;
    } else {
      return false;
    }
  }

  isOneCountryCheckedInContinent(continent) {
    if (!this.selectedTerritories || !Array.isArray(this.selectedTerritories)) { return false; }
    
    let oneCountryIsChecked = false;
    for (let i = 0; i < this.territories[continent].length; i++) {
      if (this.selectedTerritories.indexOf(this.territories[continent][i]['code']) > -1) {
        oneCountryIsChecked = true;
        break;
      }
    }
    return oneCountryIsChecked;
  }
  
  // updateContinentsWithOneChecked() {
  //   this.continents.map(continent => {
  //     this.continentsWithOneChecked[continent] = false;
  //     for (let i = 0; i < this.territories[continent].length; i++) {
  //       if (this.selectedTerritories.indexOf(this.territories[continent][i]['code']) > -1) {
  //         this.continentsWithOneChecked[continent] = true;
  //         break;
  //       }
  //     }
  //   });
  // }
}
