import { Component, EventEmitter, Output, Input } from '@angular/core';
import { Globals } from 'src/app/globals';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  @Input()
  mode: string;
  @Output()
  toggleSidebar = new EventEmitter<void>();

  constructor(public globals: Globals, private router: Router) {

  }

  updateDemoMode() {
    let url = this.router.url;
    this.router.navigateByUrl('/users', { skipLocationChange: true }).then(() =>
      this.router.navigate([url]));
  }
}
