import { Injectable } from '@angular/core';
import { Globals } from 'src/app/globals';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

let MENU = [];

@Injectable()
export class MenuService {
  constructor(private globals: Globals) { }
  getAll(): Menu[] {

    return MENU;

  }
}