import { Component } from '@angular/core';
import { MenuService } from './menu.service';

import { TranslateService } from '@ngx-translate/core';
import { Globals } from 'src/app/globals';
/* tslint:disable:max-line-length */
// *ngIf="globals.featureFlags.deliveryPoints && globals.userAccess.connections"   (condition for connections which was removed)
@Component({
  selector: 'app-menu',
  template: `

  <ul class="navigation" appAccordion>

    <li class="navigation-item" appAccordionLink  *ngIf="globals.userAccess.assets && globals.userAccess.contracts">
      <a class="navigation-link" appAccordionToggle [routerLink]="['/dashboard']">
        <i class="icon icon-basic-accelerator"></i>
        <span>Dashboard</span>
        <span class="mr-auto"></span>
      </a>
    </li>
    <li class="navigation-item" appAccordionLink *ngIf="globals.userAccess.accounts">
      <a class="navigation-link" appAccordionToggle [routerLink]="['/accounts']">
        <i class="icon icon-basic-sheet-multiple"></i>
        <span>Accounts</span>
        <span class="mr-auto"></span>
      </a>
    </li>

    <li class="navigation-item" appAccordionLink *ngIf="globals.userAccess.clients || globals.userAccess.users">
      <a class="navigation-link" appAccordionToggle href="javascript:;">
        <i class="icon icon-basic-lock-open"></i>
        <span>Access</span>
        <span class="mr-auto"></span>
        <i class="menu-caret icon icon-arrows-right"></i>
      </a>
      <ul class="navigation-submenu">        
        <li class="navigation-item" routerLinkActive="open"  *ngIf="globals.userAccess.clients && (globals.userType != 'Internal' || globals.globalAccountId)">
          <a [routerLink]="['/clients']" class="navigation-link relative">Clients</a>
        </li>
        <li class="navigation-item" routerLinkActive="open"  *ngIf="globals.userAccess.users">
          <a [routerLink]="['/users']" class="navigation-link relative">Users</a>
        </li>
      </ul>
    </li>

    <li class="navigation-item" appAccordionLink *ngIf="globals.userAccess.contracts || globals.userAccess.templates || globals.userAccess.statements">
      <a class="navigation-link" appAccordionToggle href="javascript:;">
      <i class="icon icon-basic-sheet-pen"></i>
        <span>Contracts</span>
        <span class="mr-auto"></span>
        <i class="menu-caret icon icon-arrows-right"></i>
      </a>
      <ul class="navigation-submenu">
        <li class="navigation-item" routerLinkActive="open" *ngIf="globals.userAccess.contracts && (globals.userType != 'Internal' || globals.globalAccountId)">
          <a [routerLink]="['/contracts']" class="navigation-link relative">Contracts</a>
        </li>
        <li class="navigation-item" routerLinkActive="open" *ngIf="globals.userAccess.templates">
          <a [routerLink]="['/templates']" class="navigation-link relative">Templates</a>
        </li>
        <li class="navigation-item" routerLinkActive="open" *ngIf="globals.userAccess.statements && (globals.userType != 'Internal' || globals.globalAccountId)">
          <a [routerLink]="['/statements']" class="navigation-link relative">Statements</a>
        </li>
      </ul>
    </li>

    <li class="navigation-item" appAccordionLink *ngIf="(
      (globals.userAccess.releases || globals.userAccess.stems || globals.userAccess.playlists) && (globals.userType !== 'Internal' || globals.globalAccountId))">
      <a class="navigation-link" appAccordionToggle href="javascript:;">
      <i class="icon icon-music-playlist" style="margin-left: 2px; color: rgba(255, 255, 255, 0.56)"></i>
        <span>Products</span>
        <span class="mr-auto"></span>
        <i class="menu-caret icon icon-arrows-right"></i>
      </a>
      <ul class="navigation-submenu">
        <li class="navigation-item" routerLinkActive="open" *ngIf="globals.userAccess.releases">
          <a [routerLink]="['/releases']" class="navigation-link relative">Releases</a>
        </li>
        <li class="navigation-item" routerLinkActive="open" *ngIf="globals.userAccess.stems">
          <a [routerLink]="['/stems']" class="navigation-link relative">Stems</a>
        </li>
        <li class="navigation-item" routerLinkActive="open" *ngIf="globals.userAccess.playlists">
          <a [routerLink]="['/playlists']" class="navigation-link relative">Playlists</a>
        </li>
      </ul>
    </li>

    <li class="navigation-item" appAccordionLink *ngIf="globals.userAccess.assets && (globals.userType != 'Internal' || globals.globalAccountId)">
      <a class="navigation-link" appAccordionToggle [routerLink]="['/assets']">
        <i class="icon icon-music-note-multiple"></i>
        <span>Assets</span>
        <span class="mr-auto"></span>
      </a>
    </li>


    <li class="navigation-item" appAccordionLink *ngIf="((globals.userAccess.works || globals.userAccess.publishers || globals.userAccess.composers) && (globals.userType !== 'Internal' || globals.globalAccountId))">
    <a class="navigation-link" appAccordionToggle href="javascript:;">
    <i class="fa fa-book" style="margin-left: 2px; color: rgba(255, 255, 255, 0.56)"></i>
    <span style="margin-left: 18px">Publishing</span>
        <span class="mr-auto"></span>
        <i class="menu-caret icon icon-arrows-right"></i>
      </a>
      <ul class="navigation-submenu">
        <li class="navigation-item" routerLinkActive="open"  *ngIf="globals.userAccess.works">
          <a [routerLink]="['/works']" class="navigation-link relative">Works</a>
        </li>
<!--        <li class="navigation-item" routerLinkActive="open"  *ngIf="globals.userAccess.composers">-->
<!--          <a [routerLink]="['/composers']" class="navigation-link relative">Composers</a>-->
<!--        </li>-->
<!--        <li class="navigation-item" routerLinkActive="open"  *ngIf="globals.userAccess.publishers">-->
<!--          <a [routerLink]="['/publishers']" class="navigation-link relative">Publishers</a>-->
<!--        </li>-->
      </ul>
    </li>

    <li class="navigation-item" appAccordionLink *ngIf="globals.featureFlags.deliveryPoints && globals.userAccess.connections">
      <a class="navigation-link" appAccordionToggle href="javascript:;">
      <i class="fa fa-share-alt" style="margin-left: 2px; color: rgba(255, 255, 255, 0.56)"></i>
        <span style="margin-left: 18px">Connections</span>
        <span class="mr-auto"></span>
        <i class="menu-caret icon icon-arrows-right"></i>
      </a>
      <ul class="navigation-submenu">
        <li class="navigation-item" routerLinkActive="open" *ngIf="globals.userType == 'Internal'">
          <a [routerLink]="['/connections']" class="navigation-link relative">Connection Points</a>
        </li>
        <li class="navigation-item" routerLinkActive="open" *ngIf="globals.userType != 'Internal' || globals.globalAccountId">
          <a [routerLink]="['/connection-settings']" class="navigation-link relative">Connection Settings</a>
        </li>
        <li class="navigation-item" routerLinkActive="open" *ngIf="globals.userType != 'Internal' || globals.globalAccountId">
          <a [routerLink]="['/previous-deliveries']" class="navigation-link relative">Previous Deliveries</a>
        </li>
      </ul>
    </li>

    <li class="navigation-item" appAccordionLink *ngIf="globals.userAccess.audiobank || globals.userAccess.importsSection || globals.userAccess.exportsSection">
      <a class="navigation-link" appAccordionToggle href="javascript:;">
      <i class="fa fa-upload" style="margin-left: 2px; color: rgba(255, 255, 255, 0.56)"></i>
        <span style="margin-left: 18px">Content Manager</span>
        <span class="mr-auto"></span>
        <i class="menu-caret icon icon-arrows-right"></i>
      </a>
      <ul class="navigation-submenu">
        <li class="navigation-item" routerLinkActive="open" *ngIf="globals.userAccess.importsSection && (globals.userType != 'Internal' || globals.globalAccountId)">
          <a [routerLink]="['/imports']" class="navigation-link relative">Imports</a>
        </li>
        <li class="navigation-item" routerLinkActive="open" *ngIf="globals.userAccess.exportsSection && (globals.userType != 'Internal' || globals.globalAccountId)">
          <a [routerLink]="['/exports']" class="navigation-link relative">Exports</a>
        </li>
        <li class="navigation-item" routerLinkActive="open" *ngIf="globals.userAccess.tagManager && (globals.userType != 'Internal' || globals.globalAccountId)">
          <a [routerLink]="['/tags']" class="navigation-link relative">Tag Manager</a>
        </li>
        <li class="navigation-item" routerLinkActive="open" *ngIf="globals.userAccess.audiobank && (globals.userType != 'Internal' || globals.globalAccountId)">
          <a [routerLink]="['/content-bank']" class="navigation-link relative">Content Bank</a>
        </li>
        <li class="navigation-item" routerLinkActive="open" *ngIf="globals.userType == 'Internal'">
        <a [routerLink]="['/restore-deleted-content']" class="navigation-link relative">Deleted Content</a>
      </li>
      </ul>
    </li>


    <li class="navigation-item" appAccordionLink *ngIf="((globals.userAccess.contacts || globals.userAccess.companies || globals.userAccess.lists) && (globals.userType !== 'Internal' || globals.globalAccountId))">
      <a class="navigation-link" appAccordionToggle href="javascript:;">
      <i class="fa fa-id-card" style="margin-left: 2px; color: rgba(255, 255, 255, 0.56)"></i>
        <span style="margin-left: 18px">Contacts</span>
        <span class="mr-auto"></span>
        <i class="menu-caret icon icon-arrows-right"></i>
      </a>
      <ul class="navigation-submenu">
        <li class="navigation-item" routerLinkActive="open" *ngIf="globals.userAccess.contacts">
          <a [routerLink]="['/individuals']" class="navigation-link relative">Individuals</a>
        </li>
        <li class="navigation-item" routerLinkActive="open" *ngIf="globals.userAccess.companies">
          <a [routerLink]="['/companies']" class="navigation-link relative">Companies</a>
        </li>
        <li class="navigation-item" routerLinkActive="open" *ngIf="globals.userAccess.lists">
          <a [routerLink]="['/lists']" class="navigation-link relative">Lists</a>
        </li>
      </ul>
    </li>

    <li class="navigation-item" appAccordionLink *ngIf="globals.userAccess.pages && (globals.userType != 'Internal' || globals.globalAccountId)">
      <a class="navigation-link" appAccordionToggle [routerLink]="['/pages']">
      <i class="fa fa-podcast" style="margin-left: 2px; color: rgba(255, 255, 255, 0.56)"></i>
      <span style="margin-left: 24px">PAGES</span>
        <span class="mr-auto"></span>
      </a>
    </li>

    <li class="navigation-item" appAccordionLink *ngIf="globals.userAccess.datadoktor && (globals.userType != 'Internal' || globals.globalAccountId)">
      <a class="navigation-link" appAccordionToggle [routerLink]="['/datadoktor']">
      <svg style="margin-left: 2px; width: 12px" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-medical-alt" class="svg-inline--fa fa-file-medical-alt fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M288 136V0H88C74.7 0 64 10.7 64 24v232H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h140.9c3 0 5.8 1.7 7.2 4.4l19.9 39.8 56.8-113.7c2.9-5.9 11.4-5.9 14.3 0l34.7 69.5H352c8.8 0 16 7.2 16 16s-7.2 16-16 16h-89.9L240 275.8l-56.8 113.7c-2.9 5.9-11.4 5.9-14.3 0L134.1 320H64v168c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H312c-13.2 0-24-10.8-24-24zm153-31L343.1 7c-4.5-4.5-10.6-7-17-7H320v128h128v-6.1c0-6.3-2.5-12.4-7-16.9z"></path></svg>
      <span style="margin-left: 24px">DataDoktor</span>
        <span class="mr-auto"></span>
      </a>
    </li>


    <li class="navigation-item" appAccordionLink *ngIf="globals.userAccess.trackAndTrace && (globals.userType != 'Internal' || globals.globalAccountId)">
      <a class="navigation-link" appAccordionToggle [routerLink]="['/rightstrack']">
      <i class="fa fa-search" style="margin-left: 2px; color: rgba(255, 255, 255, 0.56)"></i>
      <span style="margin-left: 24px">rightsTrack</span>
        <span class="mr-auto"></span>
      </a>
    </li>


    <li class="navigation-item" appAccordionLink>
      <a class="navigation-link" appAccordionToggle [routerLink]="['/settings']">
      <i class="fa fa-cogs" style="margin-left: 2px; color: rgba(255, 255, 255, 0.56)"></i>
      <span style="margin-left: 20px">Settings</span>
        <span class="mr-auto"></span>
      </a>
    </li>
  </ul>

  `,
  providers: [MenuService]
})
export class MenuComponent {
  currentLang = 'en';

  constructor(
    public menuService: MenuService,
    public translate: TranslateService,
    public globals: Globals
  ) {

  }
}
