import { Routes } from '@angular/router';

import { ConditionalAuthGuard } from './authentication/conditional.guard';
import { AdminAuthGuard } from './authentication/admin.guard';
import { AuthGuard } from './authentication/auth.guard';
import { UserAccessGuard } from './authentication/user-access.guard'; // Import the new guard

import { AdminLayoutComponent } from './core';
import { AuthLayoutComponent } from './core';
import { Globals } from './globals';
import { PagesLayoutComponent } from './core/pages-layout/auth-layout.component';

let globals: Globals;

export const AppRoutes: Routes = [{
  path: '',
  component: AdminLayoutComponent,
  canActivate: [AuthGuard],
  children: [
    {
      path: '',
      redirectTo: new Globals().redirectPage || '',
      pathMatch: 'full'
    },
    {
      path: 'dashboard',
      children: [
        {
          path: '',
          loadChildren: './dashboard/dashboard.module#DashboardModule'
        }
      ]
    },
    {
      path: 'accounts',
      children: [
        {
          path: '',
          loadChildren: './accounts/accounts.module#AccountsModule',
          canActivate: [AdminAuthGuard, UserAccessGuard],
          data: { accessProperty: 'accounts' }
        },
        {
          path: 'new',
          loadChildren: './accounts/edit/edit.module#EditModule',
          canActivate: [AdminAuthGuard, UserAccessGuard],
          data: { accessProperty: 'accounts' }
        },
        {
          path: ':id/edit',
          loadChildren: './accounts/edit/edit.module#EditModule',
          canActivate: [AdminAuthGuard, UserAccessGuard],
          data: { accessProperty: 'accounts' }
        }
      ]
    },
    {
      path: 'restore-deleted-content',
      children: [
        {
          path: '',
          loadChildren: './restore-deleted-content/restore-deleted-content.module#RestoreDeletedContentModule',
          canActivate: [AdminAuthGuard]
        }
      ]
    },
    {
      path: 'clients',
      children: [
        {
          path: '',
          loadChildren: './clients/clients.module#ClientsModule',
          canActivate: [ConditionalAuthGuard, UserAccessGuard],
          data: { accessProperty: 'clients' }
        },
        {
          path: 'new',
          loadChildren: './clients/edit/edit.module#EditModule',
          canActivate: [UserAccessGuard],
          data: { accessProperty: 'clients' }
        },
        {
          path: ':id/edit',
          loadChildren: './clients/edit/edit.module#EditModule',
          canActivate: [UserAccessGuard],
          data: { accessProperty: 'clients' }
        }
      ]
    },
    {
      path: 'contracts',
      children: [
        {
          path: '',
          loadChildren: './contracts/contracts.module#ContractsModule',
          canActivate: [ConditionalAuthGuard, UserAccessGuard],
          data: { accessProperty: 'contracts' }
        },
        {
          path: 'new',
          loadChildren: './contracts/edit/edit.module#EditModule',
          canActivate: [UserAccessGuard],
          data: { accessProperty: 'contracts' }
        },
        {
          path: ':id/edit',
          loadChildren: './contracts/edit/edit.module#EditModule',
          canActivate: [UserAccessGuard],
          data: { accessProperty: 'contracts' }
        }
      ]
    },
    {
      path: 'templates',
      children: [
        {
          path: '',
          loadChildren: './templates/templates.module#TemplatesModule',
          canActivate: [ConditionalAuthGuard, UserAccessGuard],
          data: { accessProperty: 'templates' }
        },
        {
          path: 'new',
          loadChildren: './templates/edit/edit.module#EditModule',
          canActivate: [UserAccessGuard],
          data: { accessProperty: 'templates' }
        },
        {
          path: ':id/edit',
          loadChildren: './templates/edit/edit.module#EditModule',
          canActivate: [UserAccessGuard],
          data: { accessProperty: 'templates' }
        }
      ]
    },
    {
      path: 'import-templates',
      children: [
        {
          path: '',
          loadChildren: './import-templates/import-templates.module#ImportTemplatesModule',
          canActivate: [ConditionalAuthGuard, UserAccessGuard],
          data: { accessProperty: 'importsSection' }
        },
        {
          path: 'new',
          loadChildren: './import-templates/edit/edit.module#EditModule',
          canActivate: [UserAccessGuard],
          data: { accessProperty: 'importsSection' }
        },
        {
          path: ':id/edit',
          loadChildren: './import-templates/edit/edit.module#EditModule',
          canActivate: [UserAccessGuard],
          data: { accessProperty: 'importsSection' }
        }
      ]
    },
    {
      path: 'export-templates',
      children: [
        {
          path: '',
          loadChildren: './export-templates/export-templates.module#ExportTemplatesModule',
          canActivate: [ConditionalAuthGuard, UserAccessGuard],
          data: { accessProperty: 'exportsSection' }
        },
        {
          path: 'new',
          loadChildren: './export-templates/edit/edit.module#EditModule',
          canActivate: [UserAccessGuard],
          data: { accessProperty: 'exportsSection' }
        },
        {
          path: ':id/edit',
          loadChildren: './export-templates/edit/edit.module#EditModule',
          canActivate: [UserAccessGuard],
          data: { accessProperty: 'exportsSection' }
        }
      ]
    },
    {
      path: 'imports',
      children: [
        {
          path: '',
          loadChildren: './imports/imports.module#ImportsModule',
          canActivate: [ConditionalAuthGuard, UserAccessGuard],
          data: { accessProperty: 'imports' }
        },
        {
          path: 'new',
          loadChildren: './imports/edit/edit.module#EditModule',
          canActivate: [UserAccessGuard],
          data: { accessProperty: 'imports' }
        },
        {
          path: ':id/edit',
          loadChildren: './imports/edit/edit.module#EditModule',
          canActivate: [UserAccessGuard],
          data: { accessProperty: 'imports' }
        }
      ]
    },
    {
      path: 'exports',
      children: [
        {
          path: '',
          loadChildren: './exports/exports.module#ExportsModule',
          canActivate: [ConditionalAuthGuard, UserAccessGuard],
          data: { accessProperty: 'exports' }
        },
        {
          path: 'new',
          loadChildren: './exports/edit/edit.module#EditModule',
          canActivate: [UserAccessGuard],
          data: { accessProperty: 'exports' }
        },
        {
          path: ':id/edit',
          loadChildren: './exports/edit/edit.module#EditModule',
          canActivate: [UserAccessGuard],
          data: { accessProperty: 'exports' }
        }
      ]
    },
    {
      path: 'datadoktor',
      children: [
        {
          path: '',
          loadChildren: './datadoktor/datadoktor.module#DatadoktorModule',
          canActivate: [UserAccessGuard],
          data: { accessProperty: 'datadoktor' }
        },
        {
          path: 'new',
          loadChildren: './datadoktor/edit/edit.module#EditModule',
          canActivate: [UserAccessGuard],
          data: { accessProperty: 'datadoktor' }
        },
        {
          path: 'templates/new',
          loadChildren: './datadoktor/new-template/edit.module#EditModule',
          canActivate: [UserAccessGuard],
          data: { accessProperty: 'datadoktor' }
        },
        {
          path: 'templates/:id/edit',
          loadChildren: './datadoktor/new-template/edit.module#EditModule',
          canActivate: [UserAccessGuard],
          data: { accessProperty: 'datadoktor' }
        },
        {
          path: ':id/edit',
          loadChildren: './datadoktor/edit/edit.module#EditModule',
          canActivate: [UserAccessGuard],
          data: { accessProperty: 'datadoktor' }
        }
      ]
    },
    {
      path: 'releases',
      children: [
        {
          path: '',
          loadChildren: './releases/releases.module#ReleasesModule',
          canActivate: [AuthGuard, UserAccessGuard],
          data: { accessProperty: 'releases' }
        },
        {
          path: 'new',
          loadChildren: './releases/edit/edit.module#EditModule',
          canActivate: [AuthGuard, UserAccessGuard],
          data: { accessProperty: 'releases' }
        },
        {
          path: ':id/edit',
          loadChildren: './releases/edit/edit.module#EditModule',
          canActivate: [AuthGuard, UserAccessGuard],
          data: { accessProperty: 'releases' }
        }
      ]
    },
    {
      path: 'assets',
      children: [
        {
          path: '',
          loadChildren: './tracks/tracks.module#TracksModule',
          canActivate: [ConditionalAuthGuard, UserAccessGuard],
          data: { accessProperty: 'assets' }
        },
        {
          path: 'new',
          loadChildren: './tracks/edit/edit.module#EditModule',
          canActivate: [UserAccessGuard],
          data: { accessProperty: 'assets' }
        },
        {
          path: ':id/edit',
          loadChildren: './tracks/edit/edit.module#EditModule',
          canActivate: [UserAccessGuard],
          data: { accessProperty: 'assets' }
        }
      ]
    },
    {
      path: 'users',
      children: [
        {
          path: '',
          loadChildren: './users/users.module#UsersModule',
          canActivate: [UserAccessGuard],
          data: { accessProperty: 'users' }
        },
        {
          path: 'new',
          loadChildren: './users/edit/edit.module#EditModule',
          canActivate: [UserAccessGuard],
          data: { accessProperty: 'users' }
        },
        {
          path: ':id/edit',
          loadChildren: './users/edit/edit.module#EditModule',
          canActivate: [UserAccessGuard],
          data: { accessProperty: 'users' }
        }
      ]
    },
    {
      path: 'statements',
      children: [
        {
          path: '',
          loadChildren: './statements/statements.module#StatementsModule',
          canActivate: [ConditionalAuthGuard, UserAccessGuard],
          data: { accessProperty: 'statements' }
        },
        {
          path: ':id/edit',
          loadChildren: './statements/edit/edit.module#EditModule',
          canActivate: [UserAccessGuard],
          data: { accessProperty: 'statements' }
        }
      ]
    },
    {
      path: 'rightstrack',
      children: [
        {
          path: '',
          loadChildren: './trackAndTrace/trackAndTrace.module#TrackAndTraceModule',
          canActivate: [UserAccessGuard],
          data: { accessProperty: 'trackAndTrace' }
        }
      ]
    },
    {
      path: 'individuals',
      children: [
        {
          path: '',
          loadChildren: './contacts/contacts.module#ContactsModule',
          canActivate: [ConditionalAuthGuard, UserAccessGuard],
          data: { accessProperty: 'contacts' }
        },
        {
          path: 'new',
          loadChildren: './contacts/edit/edit.module#EditModule',
          canActivate: [UserAccessGuard],
          data: { accessProperty: 'contacts' }
        },
        {
          path: ':id/edit',
          loadChildren: './contacts/edit/edit.module#EditModule',
          canActivate: [UserAccessGuard],
          data: { accessProperty: 'contacts' }
        }
      ]
    },
    {
      path: 'companies',
      children: [
        {
          path: '',
          loadChildren: './companies/companies.module#CompaniesModule',
          canActivate: [ConditionalAuthGuard, UserAccessGuard],
          data: { accessProperty: 'companies' }
        },
        {
          path: 'new',
          loadChildren: './companies/edit/edit.module#EditModule',
          canActivate: [UserAccessGuard],
          data: { accessProperty: 'companies' }
        },
        {
          path: ':id/edit',
          loadChildren: './companies/edit/edit.module#EditModule',
          canActivate: [UserAccessGuard],
          data: { accessProperty: 'companies' }
        }
      ]
    },
    {
      path: 'lists',
      children: [
        {
          path: '',
          loadChildren: './lists/lists.module#ListsModule',
          canActivate: [ConditionalAuthGuard, UserAccessGuard],
          data: { accessProperty: 'lists' }
        },
        {
          path: 'new',
          loadChildren: './lists/edit/edit.module#EditModule',
          canActivate: [UserAccessGuard],
          data: { accessProperty: 'lists' }
        },
        {
          path: ':id/edit',
          loadChildren: './lists/edit/edit.module#EditModule',
          canActivate: [UserAccessGuard],
          data: { accessProperty: 'lists' }
        }
      ]
    },
    {
      path: 'playlists',
      children: [
        {
          path: '',
          loadChildren: './playlists/playlists.module#PlaylistsModule',
          canActivate: [UserAccessGuard],
          data: { accessProperty: 'playlists' }
        },
        {
          path: 'new',
          loadChildren: './playlists/edit/edit.module#EditModule',
          canActivate: [UserAccessGuard],
          data: { accessProperty: 'playlists' }
        },
        {
          path: ':id/edit',
          loadChildren: './playlists/edit/edit.module#EditModule',
          canActivate: [UserAccessGuard],
          data: { accessProperty: 'playlists' }
        }
      ]
    },
    {
      path: 'stems',
      children: [
        {
          path: '',
          loadChildren: './pstems/pstems.module#PStemsModule',
          canActivate: [ConditionalAuthGuard, UserAccessGuard],
          data: { accessProperty: 'stems' }
        },
        {
          path: 'new',
          loadChildren: './pstems/edit/edit.module#EditModule',
          canActivate: [UserAccessGuard],
          data: { accessProperty: 'stems' }
        },
        {
          path: ':id/edit',
          loadChildren: './pstems/edit/edit.module#EditModule',
          canActivate: [UserAccessGuard],
          data: { accessProperty: 'stems' }
        }
      ]
    },
    {
      path: 'pages',
      children: [
        {
          path: '',
          loadChildren: './pages/pages.module#PagesModule',
          canActivate: [ConditionalAuthGuard, UserAccessGuard],
          data: { accessProperty: 'pages' }
        },
        {
          path: 'new',
          loadChildren: './pages/edit/edit.module#EditModule',
          canActivate: [UserAccessGuard],
          data: { accessProperty: 'pages' }
        },
        {
          path: ':id/edit',
          loadChildren: './pages/edit/edit.module#EditModule',
          canActivate: [UserAccessGuard],
          data: { accessProperty: 'pages' }
        }
      ]
    },
    {
      path: 'content-bank',
      children: [
        {
          path: '',
          loadChildren: './audios/audios.module#AudiosModule',
          canActivate: [ConditionalAuthGuard, UserAccessGuard],
          data: { accessProperty: 'audiobank' }
        },
        {
          path: 'new',
          loadChildren: './audios/edit/edit.module#EditModule',
          canActivate: [UserAccessGuard],
          data: { accessProperty: 'audiobank' }
        },
        {
          path: ':id/edit',
          loadChildren: './audios/edit/edit.module#EditModule',
          canActivate: [UserAccessGuard],
          data: { accessProperty: 'audiobank' }
        }
      ]
    },
    {
      path: 'works',
      children: [
        {
          path: '',
          loadChildren: './works/works.module#WorksModule',
          canActivate: [ConditionalAuthGuard, UserAccessGuard],
          data: { accessProperty: 'works' }
        },
        {
          path: 'new',
          loadChildren: './works/edit/edit.module#EditModule',
          canActivate: [UserAccessGuard],
          data: { accessProperty: 'works' }
        },
        {
          path: ':id/edit',
          loadChildren: './works/edit/edit.module#EditModule',
          canActivate: [UserAccessGuard],
          data: { accessProperty: 'works' }
        }
      ]
    },
    {
      path: 'composers',
      children: [
        {
          path: '',
          loadChildren: './composers/composers.module#ComposersModule',
          canActivate: [ConditionalAuthGuard, UserAccessGuard],
          data: { accessProperty: 'composers' }
        },
        {
          path: 'new',
          loadChildren: './composers/edit/edit.module#EditModule',
          canActivate: [UserAccessGuard],
          data: { accessProperty: 'composers' }
        },
        {
          path: ':id/edit',
          loadChildren: './composers/edit/edit.module#EditModule',
          canActivate: [UserAccessGuard],
          data: { accessProperty: 'composers' }
        }
      ]
    },
    {
      path: 'publishers',
      children: [
        {
          path: '',
          loadChildren: './publishers/publishers.module#PublishersModule',
          canActivate: [ConditionalAuthGuard, UserAccessGuard],
          data: { accessProperty: 'publishers' }
        },
        {
          path: 'new',
          loadChildren: './publishers/edit/edit.module#EditModule',
          canActivate: [UserAccessGuard],
          data: { accessProperty: 'publishers' }
        },
        {
          path: ':id/edit',
          loadChildren: './publishers/edit/edit.module#EditModule',
          canActivate: [UserAccessGuard],
          data: { accessProperty: 'publishers' }
        }
      ]
    },
    {
      path: 'connections',
      children: [
        {
          path: '',
          loadChildren: './deliveries/deliveries.module#DeliveriesModule',
          canActivate: [AdminAuthGuard, UserAccessGuard],
          data: { accessProperty: 'connections' }
        },
        {
          path: 'new',
          loadChildren: './deliveries/edit/edit.module#EditModule',
          canActivate: [UserAccessGuard],
          data: { accessProperty: 'connections' }
        },
        {
          path: ':id/edit',
          loadChildren: './deliveries/edit/edit.module#EditModule',
          canActivate: [UserAccessGuard],
          data: { accessProperty: 'connections' }
        }
      ]
    },
    {
      path: 'connection-settings',
      children: [
        {
          path: '',
          loadChildren: './deliveries-settings/deliveries-settings.module#DeliverySettingsModule',
          canActivate: [ConditionalAuthGuard, UserAccessGuard],
          data: { accessProperty: 'connections' }
        },
        {
          path: 'new',
          loadChildren: './deliveries-settings/edit/edit.module#EditModule',
          canActivate: [UserAccessGuard],
          data: { accessProperty: 'connections' }
        },
        {
          path: ':id/edit',
          loadChildren: './deliveries-settings/edit/edit.module#EditModule',
          canActivate: [UserAccessGuard],
          data: { accessProperty: 'connections' }
        }
      ]
    },
    {
      path: 'previous-deliveries',
      children: [
        {
          path: '',
          loadChildren: './previous-deliveries/previous-deliveries.module#PreviousDeliveriesModule',
          canActivate: [ConditionalAuthGuard, UserAccessGuard],
          data: { accessProperty: 'connections' }
        },
        {
          path: 'new',
          loadChildren: './previous-deliveries/edit/edit.module#EditModule',
          canActivate: [UserAccessGuard],
          data: { accessProperty: 'connections' }
        },
        {
          path: ':id/edit',
          loadChildren: './previous-deliveries/edit/edit.module#EditModule',
          canActivate: [UserAccessGuard],
          data: { accessProperty: 'connections' }
        }
      ]
    },
    {
      path: 'users',
      loadChildren: './users/users.module#UsersModule'
    },
    {
      path: 'settings',
      loadChildren: './settings/settings.module#SettingsModule'
    },
    {
      path: 'invoices/:id',
      loadChildren: './invoices/invoices.module#InvoicesModule'
    },
    {
      path: 'tags',
      children: [
        {
          path: '',
          loadChildren: './tags/tags.module#TagsModule'
        },
        {
          path: 'new',
          loadChildren: './tags/edit/edit.module#EditModule'
        },
        {
          path: ':id/edit',
          loadChildren: './tags/edit/edit.module#EditModule'
        }
      ]
    }
  ]
},
{
  path: '',
  component: AuthLayoutComponent,
  children: [{
    path: 'authentication',
    loadChildren: './authentication/authentication.module#AuthenticationModule'
  }, {
    path: 'error',
    loadChildren: './error/error.module#ErrorModule'
  }]
}, {
  path: '',
  component: PagesLayoutComponent,
  children: [{
    path: 'page',
    loadChildren: './public-pages/public-pages.module#PublicPagesModule'
  }]
},
  // {
  // path: '**',
  // redirectTo: 'error/404'
  // }
];
