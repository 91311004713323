// conditional-auth.guard.ts
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Globals } from '../globals';

//this.globals.userType != 'Internal'
//globals.globalAccountId

@Injectable({
  providedIn: 'root',
})
export class ConditionalAuthGuard implements CanActivate {
  constructor( private router: Router, private globals: Globals) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // Check your condition here
    if (this.globals.userType != 'Internal' || this.globals.globalAccountId) {
      // If the condition is met, allow access
      return true;
    } else {
      // If the condition is not met, redirect to a different page or deny access
      this.router.navigate(['/dashboard']); // Redirect to the error page or another appropriate page
      return false;
    }
  }
}
