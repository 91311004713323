import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScrollControlService {
  scrollPositionChanged: EventEmitter<number> = new EventEmitter<number>();

  setScrollPosition(position: number): void {
    this.scrollPositionChanged.emit(position);
  }
}