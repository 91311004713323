import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Globals } from '../globals';

@Injectable({
  providedIn: 'root',
})
export class UserAccessGuard implements CanActivate {
  constructor(private router: Router, private globals: Globals) {}
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const accessProperty = next.data.accessProperty;
    console.log("accessProperty========================", accessProperty);
    return new Promise((resolve) => {
      setTimeout(() => {
        console.log("this.globals.userAccess[accessProperty]========================", this.globals.userAccess[accessProperty]);
        if (this.globals.userAccess[accessProperty]) {
          resolve(true);
        } else {
          this.router.navigate(['/dashboard']); // Redirect to a different page if access is denied
          resolve(false);
        }
      }, 2000); // Wait for 10 seconds
    });
  }
}