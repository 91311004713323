import { Component, EventEmitter, Output, Input, OnInit } from '@angular/core';
import { Globals } from 'src/app/globals';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { forkJoin } from 'rxjs';
import { jwtDecode } from 'jwt-decode';
import { VersionService } from 'src/app/services/version.service';
import { Subscription } from 'rxjs';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  showSessionAlert: boolean = false;
  showUpdateNotification = false;
  private newVersionSubscription: Subscription;
  @Input()
  heading: string;
  @Output()
  toggleSidebar = new EventEmitter<void>();
  @Output()
  openSearch = new EventEmitter<void>();
  @Output()
  toggleFullscreen = new EventEmitter<void>();

  // constructor(public globals: Globals, private router: Router, private route: ActivatedRoute, private http: ApiService) { }
  constructor(public globals: Globals, private router: Router, private route: ActivatedRoute, private http: ApiService, private versionService: VersionService, private cdr: ChangeDetectorRef) { }

  logout() {
    if(this.globals.userType == 'Internal'){
      this.globals.globalAccountId = null;
    }
    localStorage.clear();
    this.router.navigate(['authentication/signin']);
  }

  ngOnInit() {
    this.checkSessionExpiry();
    this.versionService.updateToNewVersion();
    // Subscribe to new version notifications
    this.newVersionSubscription = this.versionService.newVersion$.subscribe((newVersion) => {
      if (newVersion) {
        this.showUpdateNotification = true;
        this.cdr.detectChanges();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.newVersionSubscription) {
      this.newVersionSubscription.unsubscribe();
    }
  }

  resetNotificationsCount() {
    this.globals.notificationsCounts = 0
  }

  // Method to refresh the app (called when user clicks the refresh button)
  refreshApp() {
    this.versionService.updateToNewVersion();
    location.reload();
  }
  
  refreshPage() {
    if(this.globals.globalAccountId){
      localStorage.setItem('globalAccountId', this.globals.globalAccountId);
    }
    else{
      localStorage.removeItem('globalAccountId');
    }
    // let url = this.router.url;
    // this.router.navigateByUrl('dashboard', { skipLocationChange: true }).then(() =>
    //   this.router.navigate([url]));

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    const currentUrl = this.router.url + '?';
    this.router.navigateByUrl(currentUrl).then(() => {
      this.router.navigated = false;
      this.router.navigate(['dashboard']);
    });

    if(this.globals.globalAccountId){
        this.populateGlobalTags();
        this.populateGlobalLists();
        this.populateGlobalExportTemplates();
        this.populateReadOnlyRights();
        this.populateGlobalContacts();
        this.populateGlobalCompanies();
    }
      this.populateGlobalClients();
    }

  //copied from app.component.ts to populate global data 
    // Initialized the globally stored arrays of tags.
    populateGlobalTags() {
        this.http.get('/tags/projection-fields', { limit: 10000, projection: this.globals.allTagsProjectionFields })
            .subscribe((data) => {
                this.globals.allTags = data['tags'];
                this.globals.populateGlobalTagTypes();
                this.globals.setLocalStorageItem('globalTags', this.globals.allTags, true);
            });
    }
  
    // Initialize the globally stored array of lists.
    populateGlobalLists() {
      this.http.get('/lists', { limit: 1000 })
        .subscribe((data) => {
          this.globals.lists = data['lists'];
          this.globals.setLocalStorageItem('globalLists', this.globals.lists, true);
        });
    }
  
    populateGlobalExportTemplates() {
      this.http.get('/export_templates', { limit: 10000 })
        .subscribe((data) => {
          this.globals.allExportTemplates = data['export_templates'];
          this.globals.setCustomExportTemplateVariables();
          this.globals.setLocalStorageItem('globalExportTemplates', this.globals.allExportTemplates, true);
        });
    }
  
    populateGlobalClients() {
      this.http.get('/clients', { limit: 10000 })
        .subscribe((data) => {
          this.globals.clients = data['clients'];
          this.globals.setLocalStorageItem('globalClients', this.globals.clients, true);
        });
    }
  
    populateReadOnlyRights() {
      this.http.get('/users/current/rights', {})
        .subscribe((data: any) => {
            if (data && data['readOnlyRights']) {
                this.globals.readOnlyRights = data['readOnlyRights'];
            } else if (data && data['oldRightsVariable'] && data['oldRightsVariable'] == 'Read-Only') {
                this.globals.readOnlyRights = this.globals.defaultReadOnlyRights;
                for (const setProperty in this.globals.readOnlyRights) {
                    this.globals.readOnlyRights[setProperty] = true;
                }
            } else {
                this.globals.readOnlyRights = this.globals.defaultReadOnlyRights;
            }
            this.globals.setLocalStorageItem('globalReadOnlyRights', this.globals.readOnlyRights, true);
        });
    }

    async populateGlobalContacts(attemptNum = 1) {
        if (attemptNum > 3) { return; }
        try {
            // Fetch the total count
            const countResponse = await this.http.get('/contacts/count', {}).toPromise();
            const totalNum = countResponse['count'];

            // Fetch the actual data
            const projectionResponse = await this.http.get('/contacts/projection-fields', {projection: this.globals.allContactsProjectionFields}).toPromise();

            const actualLength = projectionResponse['contacts'].length;
            if (actualLength !== totalNum) {
                console.error(`error in populateGlobalContacts (attempt: ${attemptNum}), count: (${totalNum}) != actual-length: (${actualLength})`);
                await new Promise(resolve => setTimeout(resolve, 2000));
                await this.populateGlobalContacts(attemptNum + 1);
            } else {
                this.globals.allContacts = projectionResponse['contacts'];
                this.globals.populateGlobalContactTypes();
                this.globals.setLocalStorageItem('globalContacts', this.globals.allContacts, true);
            }
        } catch (error) {
            console.error('Error in populateGlobalContacts:', error);
        }
    }

    async populateGlobalCompanies(attemptNum = 1) {
        if (attemptNum > 3) { return; }
        try {
            // Fetch the total count
            const countResponse = await this.http.get('/companies/count', {}).toPromise();
            const totalNum = countResponse['count'];

            // Fetch the actual data
            const projectionResponse = await this.http.get('/companies/projection-fields', {projection: this.globals.allCompaniesProjectionFields}).toPromise();

            const actualLength = projectionResponse['companies'].length;
            if (actualLength !== totalNum) {
                console.error(`error in populateGlobalCompanies (attempt: ${attemptNum}), count: (${totalNum}) != actual-length: (${actualLength})`);
                await new Promise(resolve => setTimeout(resolve, 2000));
                await this.populateGlobalCompanies(attemptNum + 1);
            } else {
                this.globals.allCompanies = projectionResponse['companies'];
                this.globals.populateGlobalCompanyTypes();
                this.globals.setLocalStorageItem('globalCompanies', this.globals.allCompanies, true);
            }
        } catch (error) {
            console.error('Error in populateGlobalCompanies:', error);
        }
    }

  openKB() {
    window.open('https://rightshub.outseta.com/support/kb');
  }

  getUploads(){
    let uploads = [];
    for (const key in this.globals.progressBarsWithData) {
      uploads.push(this.globals.progressBarsWithData[key]);
    }

    return uploads;
  }

  isUploadInProgress(){
    let result = false;
    for (const key in this.globals.progressBarsWithData) {
      this.globals.progressBarsWithData[key].progress != 100 ? result = true : null;
    }

    return result;
  }

  checkSessionExpiry(): void {  //later added by arba-v to check token expiry and show alert message.
    const token = localStorage.getItem('token'); // Replace with your storage method
    if (!token) return;

    try {
      const decodedToken: any = jwtDecode(token); // Decode the JWT
      const expiryTime = decodedToken.exp * 1000; // Convert expiry to milliseconds
      const currentTime = Date.now();

      // Calculate time left until expiry
      const timeLeft = expiryTime - currentTime;

      // If less than 1 hour left, show the alert immediately
      if (timeLeft <= 3600000) {
        this.showSessionAlert = true;
      } else {
        // Set a timer to show the alert 1 hour before expiry
        setTimeout(() => {
          this.showSessionAlert = true;
        }, timeLeft - 3600000); // Trigger alert 1 hour before expiry
      }
    } catch (error) {
      console.error('Error decoding JWT:', error);
    }
  }
}
